import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import { IoNewspaperOutline } from "react-icons/io5";

import Header from "../../component/header";
import SubHeader from "./subHeader";
import Footer from "../../component/footer/footer";
import GoogleAds from "../../component/adds/googleAdds";

import { API } from "../../Config/API";
import { POST } from "../../utils/apiCalls";
import moment from "moment";

import { useNavigate, useSearchParams } from "react-router-dom";
import { getURL } from "../../utils/getCdnURL";
import StickyAdds from "../../component/adds/stickyAdds";

function Homescreen() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const format = "YYYY-MM-DD";
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const date = searchParams.get("date");
  const edition = searchParams.get("edition");

  useEffect(() => {
    let obj = {
      type: "all",
      edition: edition,
      limit: 20,
      createdAt: moment(new Date()).format("YYYY-MM-DD"),
    };
    if (edition) {
    } else {
      loadData(obj);
    }
  }, []);

  useEffect(() => {
    const dates = searchParams.get("date");
    const editions = searchParams.get("edition");
    if (dates || editions) {
      let obj = {
        type: "all",
        edition: editions,
        limit: 20,
        createdAt: dates,
      };
      loadData(obj);
    }
  }, [searchParams]);

  const loadData = async (obj: any) => {
    try {
      setIsLoading(true);
      let url = API.EPAPER;
      const response: any = await POST(url, obj);
      if (response?.length) {
        setData(response);
      } else {
        setData([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleClick = (item: any) => {
    navigate(
      `/details/${item?.edition}/${moment(item?.date).format(format)}/1`
    );
  };

  return (
    <div>
      <Header onChange={(val: any) => loadData(val)} />
      <SubHeader
        edition={edition}
        date={date ? date : moment(new Date()).format("YYYY-MM-DD")}
      />
      <div className="Homescreen-box">
        <Container>
          <GoogleAds />
          <br />
          <div className="Homescreen-txt1">
            {edition ? edition + " Edition" : "Main Editions"}
          </div>
          {isLoading ? (
            <div className="Homescreen-loading">Loading . . .</div>
          ) : data?.length ? (
            <Row>
              {data?.map((item: any) => {
                let imageURL = getURL(item?.image);
                return (
                  <Col sm={3} key={item?.id} onClick={() => handleClick(item)}>
                    <div className="Homescreen-item">
                      <div className="Homescreen-itemBox">
                        <img
                          src={imageURL}
                          className="Homescreen-itemImg"
                          alt="suprabhaatham-epaper"
                        />
                      </div>

                      <div className="Homescreen-itemBox2">
                        <div>
                          {item?.edition} -
                          {moment(item?.date).format("DD-MM-yyyy")}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <div className="Homescreen-loading">
              <IoNewspaperOutline size={50} color="grey" />
              <div style={{ margin: 10 }} />
              <div>Sorry Epaper not found</div>
            </div>
          )}
          <GoogleAds />
        </Container>
        <StickyAdds />
      </div>
      <Footer />
    </div>
  );
}

export default Homescreen;
