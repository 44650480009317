import { useRef, useState } from "react";
import { Button, Input, Modal, message, Image } from "antd";
import { FaRegClipboard } from "react-icons/fa";
import { AiOutlineZoomIn } from "react-icons/ai";

import Logo from "../../../asset/images/logo.png";
import LogoGulf from "../../../asset/images/gulf-logo.png";
import Loading from "../../../asset/images/loading.gif";
import moment from "moment";

import {
  MdFacebook,
  MdOutlineWhatsapp,
  MdDownloadForOffline,
  MdLink,
} from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";

import DivImage from "../helpers/divImage";
import { UPLOADTOS3 } from "../../../utils/apiCalls";
import { AiOutlinePrinter } from "react-icons/ai";
import ReactToPrint from "react-to-print";
import ZoomPopup from "./zoomPopup";

const SharePopup = (props: any) => {
  const [shareurl, setShareUrl] = useState(null) as any;
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [activeZoom, setActiveZoom] = useState<any>(false);
  const sharePopup = useRef<HTMLImageElement>(null);
  var url = props?.file && URL?.createObjectURL(props?.file);

  const GenerateFile = async () => {
    let logos = props?.edition === "Gulf" ? LogoGulf : Logo;
    let file: any = await DivImage(url, logos, props);
    return file;
  };

  const Download = async () => {
    let filename = await GenerateFile();
    let name = moment(new Date()).unix();
    var a = document.createElement("a");
    a.href = filename;
    a.download = name + "-epaper.jpg";
    a.click();
  };

  const Createlink = async (type: any) => {
    setLoading(true);
    setType(type);
    let filename = await GenerateFile();
    let name = moment(new Date()).unix() + "suprabhatham";
    if (!shareurl) {
      const res: any = await UPLOADTOS3(filename, name);
      let urllink = `https://epaper.suprabhaatham.in/preview?url=+${res.Location}`;
      console.log("urllink", urllink);
      setShareUrl(urllink);
      shareLink(type, urllink);
    } else {
      shareLink(type, shareurl);
    }
    setLoading(false);
  };

  const shareLink = (type: any, link: any) => {
    if (type === "whatsapp") {
      const whatsappMessage = `Check out this link! ${link}`;
      const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        whatsappMessage
      )}`;
      window.open(whatsappURL);
    } else if (type === "fb") {
      const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        link
      )}`;
      window.open(facebookURL);
    } else if (type === "twitter") {
      const tweetText = "Check out this link!";
      const twitterURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        tweetText
      )}&url=${encodeURIComponent(link)}`;
      window.open(twitterURL);
    } else if (type === "mail") {
      const subject = "Interesting Link to Check Out";
      const emailBody = `Hi there,\n\nI found this link very interesting and thought you might like it too:\n\n${link}\n\nBest regards,`;
      const emailURL = `mailto:?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(emailBody)}`;
      window.open(emailURL);
    } else if (type === "link") {
      handleUrlCopy();
    }
  };

  const handleUrlCopy = () => {
    navigator.clipboard.writeText(shareurl).then(() => {
      message.success("link copied");
    });
  };

  return (
    <Modal
      title="Share Epaper"
      open={props?.visible}
      onCancel={() => props?.onClose()}
      footer={true}
      width={700}
    >
      <div className="SharePopup-share">
        <Button
          block
          size="large"
          loading={loading && type === "fb"}
          onClick={() => Createlink("fb")}
        >
          <MdFacebook className="SharePopup-icon" />
        </Button>
        <Button
          block
          size="large"
          loading={loading && type === "whatsapp"}
          onClick={() => Createlink("whatsapp")}
        >
          <MdOutlineWhatsapp className="SharePopup-icon" />
        </Button>
        <Button
          block
          size="large"
          loading={loading && type === "twitter"}
          onClick={() => Createlink("twitter")}
        >
          <FaXTwitter className="SharePopup-icon" />
        </Button>
        <Button
          block
          size="large"
          loading={loading && type === "link"}
          onClick={() => Createlink("link")}
        >
          <MdLink className="SharePopup-icon" />
        </Button>
        <ReactToPrint
          trigger={() => {
            return (
              <Button block size="large">
                <AiOutlinePrinter className="SharePopup-icon" />
              </Button>
            );
          }}
          content={() => sharePopup?.current}
        />
        <Button block size="large" onClick={() => Download()}>
          <MdDownloadForOffline className="SharePopup-icon" />
        </Button>
      </div>
      {shareurl ? (
        <div className="Share-Input">
          <Input
            value={shareurl}
            prefix={
              <div className="Share-Icon" onClick={handleUrlCopy}>
                <FaRegClipboard size={20} color="green" />
              </div>
            }
          />
        </div>
      ) : null}
      {props.loading ? (
        <div className="SharePopup-loading">
          <img
            src={Loading}
            style={{
              height: 200,
              width: 200,
              objectFit: "cover",
            }}
          />
        </div>
      ) : (
        <div className="SharePopup">
          <div className="SharePopup-box" ref={sharePopup}>
            {props?.edition === "Gulf" ? (
              <img src={LogoGulf} className="SharePopup-logo" />
            ) : (
              <img src={Logo} className="SharePopup-logo" />
            )}
            <div
              className="SharePopup-img-container"
              onClick={() => setActiveZoom(true)}
            >
              <img src={url} className="SharePopup-img" />
              <div className="zoom-icon">
                <div>
                  <AiOutlineZoomIn size={25} />
                </div>
                <div>Enlarge</div>
              </div>
            </div>
            <div className="SharePopup-txt1">{props?.edition} Edition</div>
            <div className="SharePopup-txt1">
              Date : {moment(props?.date).format("l")} , Page :{" "}
              {props?.page + 1}
            </div>
          </div>
          <br />
        </div>
      )}
      {activeZoom ? (
        <ZoomPopup
          show={activeZoom}
          onClose={() => setActiveZoom(false)}
          src={url}
        />
      ) : null}
    </Modal>
  );
};

export default SharePopup;
